function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { filename } from "../../utils/InputUtils";
export var generateDefaultPolizaEndorsement = function generateDefaultPolizaEndorsement(_ref) {
  var _polizaEndorsement$po, _polizaEndorsement$po2, _polizaEndorsement$po3, _polizaEndorsement$po4, _polizaEndorsement$po5, _polizaEndorsement$po6, _polizaEndorsement$po7, _polizaEndorsement$po8;

  var polizaEndorsement = _ref.polizaEndorsement;
  return _objectSpread(_objectSpread({}, polizaEndorsement), {}, {
    start_date: generateDate(polizaEndorsement.start_date),
    finish_date: generateDate(polizaEndorsement.finish_date),
    poliza_params: {
      start_date: (polizaEndorsement === null || polizaEndorsement === void 0 ? void 0 : (_polizaEndorsement$po = polizaEndorsement.poliza_params) === null || _polizaEndorsement$po === void 0 ? void 0 : _polizaEndorsement$po.start_date) && generateDate(polizaEndorsement === null || polizaEndorsement === void 0 ? void 0 : (_polizaEndorsement$po2 = polizaEndorsement.poliza_params) === null || _polizaEndorsement$po2 === void 0 ? void 0 : _polizaEndorsement$po2.start_date),
      finish_date: (polizaEndorsement === null || polizaEndorsement === void 0 ? void 0 : (_polizaEndorsement$po3 = polizaEndorsement.poliza_params) === null || _polizaEndorsement$po3 === void 0 ? void 0 : _polizaEndorsement$po3.finish_date) && generateDate(polizaEndorsement === null || polizaEndorsement === void 0 ? void 0 : (_polizaEndorsement$po4 = polizaEndorsement.poliza_params) === null || _polizaEndorsement$po4 === void 0 ? void 0 : _polizaEndorsement$po4.finish_date),
      amount_change_date: (polizaEndorsement === null || polizaEndorsement === void 0 ? void 0 : (_polizaEndorsement$po5 = polizaEndorsement.poliza_params) === null || _polizaEndorsement$po5 === void 0 ? void 0 : _polizaEndorsement$po5.amount_change_date) && generateDate(polizaEndorsement === null || polizaEndorsement === void 0 ? void 0 : (_polizaEndorsement$po6 = polizaEndorsement.poliza_params) === null || _polizaEndorsement$po6 === void 0 ? void 0 : _polizaEndorsement$po6.amount_change_date),
      amount_change_difference: polizaEndorsement === null || polizaEndorsement === void 0 ? void 0 : (_polizaEndorsement$po7 = polizaEndorsement.poliza_params) === null || _polizaEndorsement$po7 === void 0 ? void 0 : _polizaEndorsement$po7.amount_change_difference,
      new_asegurado_amount: polizaEndorsement === null || polizaEndorsement === void 0 ? void 0 : (_polizaEndorsement$po8 = polizaEndorsement.poliza_params) === null || _polizaEndorsement$po8 === void 0 ? void 0 : _polizaEndorsement$po8.new_asegurado_amount
    }
  });
};
export var formatDate = function formatDate(date) {
  if (date && date.split("-").length === 3) {
    var _date$split = date.split("-"),
        _date$split2 = _slicedToArray(_date$split, 3),
        year = _date$split2[0],
        month = _date$split2[1],
        day = _date$split2[2];

    return "".concat(day, "-").concat(month, "-").concat(year);
  }

  return null;
};

var generateDate = function generateDate(date) {
  var defaultDate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();

  if (date && date.split("-").length === 3) {
    var _date$split3 = date.split("-"),
        _date$split4 = _slicedToArray(_date$split3, 3),
        day = _date$split4[0],
        month = _date$split4[1],
        year = _date$split4[2];

    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

  return defaultDate;
};

export function addMonths(date, months) {
  var newDate = new Date(date);
  newDate.setMonth(date.getMonth() + months);
  return newDate;
}
export var getEndorsementDocumentFilename = function getEndorsementDocumentFilename(path) {
  return filename(path).replace(/%20/g, " ");
};